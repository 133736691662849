<script setup>
const props = defineProps({
    width: {
        type: Number,
        default: 25,
    },
    height: {
        type: Number,
        default: 24,
    },
});
</script>

<template>
    <svg :width="props.width" :height="props.height" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2047_8922)">
            <path d="M23.1494 0H1.8457C0.825195 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.825195 24 1.8457 24H23.1494C24.1699 24 25 23.2219 25 22.2703V1.72969C25 0.773438 24.1699 0 23.1494 0ZM7.41699 20.4516H3.70605V8.99531H7.41699V20.4516ZM5.56152 7.43438C4.37012 7.43438 3.4082 6.51094 3.4082 5.37187C3.4082 4.23281 4.37012 3.30937 5.56152 3.30937C6.74805 3.30937 7.70996 4.23281 7.70996 5.37187C7.70996 6.50625 6.74805 7.43438 5.56152 7.43438ZM21.3037 20.4516H17.5977V14.8828C17.5977 13.5562 17.5732 11.8453 15.6689 11.8453C13.7402 11.8453 13.4473 13.2937 13.4473 14.7891V20.4516H9.74609V8.99531H13.3008V10.5609H13.3496C13.8428 9.66094 15.0537 8.70938 16.8555 8.70938C20.6104 8.70938 21.3037 11.0813 21.3037 14.1656V20.4516Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_2047_8922">
                <rect :width="props.width" :height="props.height" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
