<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1042.000000 1042.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,1042.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
            <path d="M3844 10028 c-112 -122 -264 -348 -364 -540 -180 -348 -263 -665
            -264 -1008 -1 -204 17 -339 74 -565 80 -321 219 -608 400 -830 74 -91 231
            -241 314 -300 207 -148 477 -262 724 -305 68 -13 98 -30 50 -30 -13 0 -67 -11
            -120 -25 -177 -45 -335 -107 -474 -186 -354 -202 -603 -492 -775 -903 l-38
            -91 -605 -3 -606 -2 0 -205 0 -205 556 0 556 0 -6 -37 c-8 -48 -26 -221 -26
            -250 l0 -23 -540 0 -540 0 0 -200 0 -200 540 0 c424 0 541 -3 544 -12 2 -7 9
            -58 15 -113 7 -55 15 -121 18 -147 l6 -48 -562 0 -561 0 0 -200 0 -200 623 0
            622 0 60 -127 c95 -202 196 -345 349 -499 198 -197 380 -310 649 -403 237 -82
            458 -115 762 -114 229 0 371 15 560 60 561 132 971 471 1208 998 l36 80 616 3
            615 2 0 200 0 200 -549 0 c-303 0 -552 3 -554 8 -2 4 6 75 17 157 l22 150 532
            3 532 2 0 200 0 200 -530 0 -529 0 -5 33 c-3 17 -7 66 -11 107 -3 41 -8 96
            -11 123 l-7 47 547 0 546 0 0 205 0 205 -598 0 -597 0 -29 75 c-74 193 -203
            408 -340 566 -232 268 -604 481 -968 556 -49 9 -88 20 -88 23 0 3 48 15 108
            27 160 33 280 76 447 158 358 177 609 440 801 840 166 344 255 793 225 1130
            -34 373 -158 727 -380 1080 -71 114 -183 264 -246 331 l-46 49 45 -71 c296
            -469 455 -1138 383 -1614 -123 -805 -551 -1344 -1255 -1579 -294 -99 -514
            -118 -755 -67 -592 124 -1046 458 -1297 956 -142 282 -242 730 -226 1010 27
            461 169 923 389 1273 31 48 54 87 53 87 -2 0 -21 -19 -42 -42z m1678 -3788
            c272 -57 471 -163 658 -350 263 -262 419 -626 482 -1130 20 -161 17 -681 -5
            -840 -49 -346 -155 -655 -303 -878 -71 -105 -215 -258 -317 -333 -153 -114
            -370 -201 -582 -234 -146 -23 -428 -16 -556 14 -121 28 -226 64 -322 112 -455
            229 -730 708 -803 1397 -23 212 -15 673 15 865 41 263 106 471 212 678 77 148
            163 263 288 380 191 182 412 286 708 334 125 20 396 13 525 -15z"/>
            <path d="M4270 1654 c-230 -61 -391 -241 -447 -499 -23 -107 -13 -296 21 -400
            66 -205 214 -350 406 -400 84 -22 252 -22 344 -1 167 39 311 154 384 306 60
            125 75 211 70 383 -4 119 -9 155 -31 223 -52 157 -154 278 -292 344 -44 21
            -104 43 -133 49 -74 15 -256 13 -322 -5z m275 -240 c130 -62 201 -224 192
            -439 -10 -241 -113 -389 -280 -402 -201 -16 -336 156 -337 429 0 223 87 379
            240 429 39 13 142 4 185 -17z"/>
            <path d="M2875 1656 c-5 -3 -45 -7 -87 -11 l-78 -7 0 -638 0 -637 38 -6 c161
            -25 472 -15 606 19 132 34 242 119 289 222 32 72 30 198 -4 273 -34 72 -87
            124 -161 159 l-60 29 34 17 c57 29 107 74 136 122 24 42 27 57 27 137 0 105
            -18 148 -84 208 -99 89 -175 109 -436 113 -115 2 -214 2 -220 0z m325 -217
            c95 -20 146 -95 122 -177 -24 -80 -107 -122 -242 -122 l-80 0 0 149 c0 147 0
            150 23 154 44 9 127 7 177 -4z m5 -524 c113 -29 166 -100 151 -200 -16 -101
            -111 -155 -273 -155 l-83 0 0 185 0 185 73 0 c40 0 100 -7 132 -15z"/>
            <path d="M6565 1649 c-44 -5 -86 -11 -92 -14 -10 -3 -13 -140 -13 -638 l0
            -634 48 -7 c112 -14 324 -18 429 -7 307 31 489 133 591 328 63 122 76 184 76
            358 0 137 -2 157 -27 228 -15 43 -42 102 -61 130 -72 114 -215 210 -361 243
            -84 19 -466 28 -590 13z m480 -234 c154 -45 241 -169 252 -355 19 -313 -162
            -502 -467 -488 l-65 3 -3 427 -2 427 37 4 c62 8 195 -2 248 -18z"/>
            <path d="M5491 1618 c-33 -106 -381 -1248 -381 -1253 0 -3 69 -4 152 -3 l153
            3 44 163 44 162 186 -2 185 -3 34 -110 c18 -60 40 -134 48 -163 l15 -52 161 0
            c124 0 159 3 155 13 -2 6 -86 275 -186 597 -100 322 -189 606 -197 633 l-16
            47 -193 0 -193 0 -11 -32z m302 -588 l34 -115 -135 -3 c-74 -1 -137 -1 -139 2
            -3 2 16 75 40 163 24 87 55 203 67 258 l23 100 38 -145 c21 -80 53 -197 72
            -260z"/>
        </g>
    </svg>
</template>
