<script setup>
const props = defineProps({
    width: {
        type: Number,
        default: 25,
    },
    height: {
        type: Number,
        default: 26,
    },
});
</script>

<template>
    <svg :width="props.width" :height="props.height" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2047_8923)">
            <path d="M24.751 7.80117C24.751 7.80117 24.5068 6.00859 23.7549 5.22148C22.8027 4.18555 21.7383 4.18047 21.25 4.11953C17.7539 3.85547 12.5049 3.85547 12.5049 3.85547H12.4951C12.4951 3.85547 7.24609 3.85547 3.75 4.11953C3.26172 4.18047 2.19727 4.18555 1.24512 5.22148C0.493164 6.00859 0.253906 7.80117 0.253906 7.80117C0.253906 7.80117 0 9.90859 0 12.0109V13.9812C0 16.0836 0.249023 18.191 0.249023 18.191C0.249023 18.191 0.493164 19.9836 1.24023 20.7707C2.19238 21.8066 3.44238 21.7711 3.99902 21.8828C6.00098 22.0809 12.5 22.1418 12.5 22.1418C12.5 22.1418 17.7539 22.1316 21.25 21.8727C21.7383 21.8117 22.8027 21.8066 23.7549 20.7707C24.5068 19.9836 24.751 18.191 24.751 18.191C24.751 18.191 25 16.0887 25 13.9812V12.0109C25 9.90859 24.751 7.80117 24.751 7.80117ZM9.91699 16.373V9.06563L16.6699 12.732L9.91699 16.373Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_2047_8923">
                <rect :width="props.width" :height="props.height" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
