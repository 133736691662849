<template>
    <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3592_9922)">
            <path d="M12 14.8728C13.1046 14.8728 14 13.9656 14 12.8466C14 11.7275 13.1046 10.8203 12 10.8203C10.8954 10.8203 10 11.7275 10 12.8466C10 13.9656 10.8954 14.8728 12 14.8728Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 10.1453C5.10457 10.1453 6 9.23809 6 8.11902C6 6.99996 5.10457 6.09277 4 6.09277C2.89543 6.09277 2 6.99996 2 8.11902C2 9.23809 2.89543 10.1453 4 10.1453Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.72656 9.13867L10.2799 11.8268" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 5.41675C13.1046 5.41675 14 4.50957 14 3.39051C14 2.27144 13.1046 1.36426 12 1.36426C10.8954 1.36426 10 2.27144 10 3.39051C10 4.50957 10.8954 5.41675 12 5.41675Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.2732 4.41016L5.72656 7.09831" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_3592_9922">
                <rect width="16" height="16.21" fill="white" transform="translate(0 0.0136719)"/>
            </clipPath>
        </defs>
    </svg>
</template>
