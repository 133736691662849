<script setup>
const props = defineProps({
    width: {
        type: Number,
        default: 25,
    },
    height: {
        type: Number,
        default: 24,
    },
});
</script>

<template>
    <svg :width="props.width" :height="props.height" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2047_8919)">
            <path d="M25 12C25 5.37258 19.4036 0 12.5 0C5.59644 0 0 5.37258 0 12C0 17.9895 4.57104 22.954 10.5469 23.8542V15.4688H7.37305V12H10.5469V9.35625C10.5469 6.34875 12.4131 4.6875 15.2683 4.6875C16.6355 4.6875 18.0664 4.92188 18.0664 4.92188V7.875H16.4902C14.9375 7.875 14.4531 8.80008 14.4531 9.75V12H17.9199L17.3657 15.4688H14.4531V23.8542C20.429 22.954 25 17.9895 25 12Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_2047_8919">
                <rect :width="props.width" :height="props.height" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
