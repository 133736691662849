<script setup>
const props = defineProps({
    width: {
        type: Number,
        default: 25,
    },
    height: {
        type: Number,
        default: 24,
    },
});
</script>

<template>
    <svg :width="props.width" :height="props.height" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2047_8920)">
            <path d="M7.86475 21.7507C17.2964 21.7507 22.4565 14.2474 22.4565 7.74259C22.4565 7.53166 22.4517 7.31603 22.4419 7.10509C23.4457 6.40819 24.312 5.54499 25 4.55603C24.0651 4.95533 23.0725 5.21611 22.0562 5.32947C23.1263 4.71364 23.9276 3.7462 24.3115 2.6065C23.3047 3.17929 22.2037 3.58334 21.0557 3.80134C20.2821 3.01229 19.2594 2.48985 18.1455 2.31478C17.0316 2.13972 15.8887 2.32178 14.8935 2.83283C13.8982 3.34388 13.106 4.15544 12.6394 5.14204C12.1728 6.12865 12.0577 7.23535 12.312 8.29103C10.2734 8.19282 8.2791 7.68444 6.45831 6.79883C4.63753 5.91323 3.03093 4.67017 1.74268 3.15025C1.08792 4.23398 0.887559 5.51638 1.18232 6.73682C1.47709 7.95727 2.24486 9.02418 3.32959 9.72072C2.51524 9.6959 1.71872 9.48541 1.00586 9.10666V9.16759C1.00513 10.3049 1.41469 11.4073 2.16491 12.2875C2.91513 13.1677 3.95971 13.7713 5.12109 13.9957C4.36672 14.1939 3.57498 14.2227 2.80713 14.0801C3.13485 15.0582 3.77247 15.9136 4.63101 16.5271C5.48955 17.1405 6.52617 17.4813 7.59619 17.502C5.77961 18.8718 3.5356 19.6149 1.22559 19.6113C0.815924 19.6107 0.406666 19.5866 0 19.5392C2.34673 20.9845 5.07659 21.7521 7.86475 21.7507Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_2047_8920">
                <rect :width="props.width" :height="props.height" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
