export const i18nPlugin = {
    install(app, options) {
        app.config.globalProperties.$__ = (key, replace = {}) => {
            let translation = options.translations[key]
                ? options.translations[key]
                : key;

            Object.keys(replace).forEach(function (key) {
                translation = translation.replace(':' + key, replace[key])
            });

            return translation;
        }

        app.provide('$__', app.config.globalProperties.$__);
    }
}
