<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
            <path d="M65 4151 c-11 -5 -29 -19 -40 -31 -20 -22 -20 -38 -23 -1014 -3 -903
            -2 -994 13 -1022 9 -18 28 -37 41 -43 17 -7 139 -11 378 -11 406 0 391 -3 422
            93 l16 52 177 -176 c164 -164 285 -266 621 -530 215 -169 474 -361 535 -398
            83 -50 243 -102 334 -108 86 -6 185 14 231 47 26 18 34 19 75 9 137 -35 300
            39 396 177 27 40 29 41 77 36 137 -13 301 69 367 183 23 39 29 42 91 54 83 15
            133 40 195 98 68 64 101 138 105 233 l2 75 74 52 c40 29 78 52 83 52 6 1 19
            -27 30 -61 34 -106 20 -102 408 -106 369 -4 396 0 427 51 19 31 20 58 20 1017
            0 954 -1 986 -19 1016 -32 52 -62 57 -301 44 -378 -19 -664 -66 -941 -156
            -190 -61 -236 -95 -226 -166 l5 -37 -52 20 c-28 12 -78 31 -111 44 -33 12
            -174 70 -313 129 -295 125 -402 156 -541 156 -155 0 -280 -43 -437 -151 l-70
            -49 -423 0 -423 0 7 64 c5 49 3 70 -9 95 -54 103 -341 188 -813 240 -229 25
            -363 33 -388 22z m340 -231 c266 -30 498 -73 603 -112 l53 -20 -6 -46 c-39
            -284 -187 -862 -376 -1469 l-11 -33 -229 0 -229 0 0 850 c0 468 3 850 8 850 4
            0 88 -9 187 -20z m4505 -1040 l0 -850 -229 0 -229 0 -42 133 c-105 331 -345
            989 -456 1252 -35 82 -60 152 -56 156 11 10 187 57 312 83 202 42 447 72 618
            75 l82 1 0 -850z m-2167 824 c40 -9 113 -34 162 -54 50 -21 187 -78 305 -127
            118 -49 293 -119 388 -154 l172 -64 61 -155 c120 -306 346 -947 338 -954 -2
            -2 -46 -31 -97 -65 l-93 -62 -26 33 c-107 131 -311 315 -678 613 -268 218
            -380 311 -465 391 -82 76 -108 94 -139 94 -13 0 -80 -22 -148 -50 -68 -27
            -134 -50 -146 -50 -39 -1 -98 -28 -115 -52 -33 -48 -118 -131 -158 -154 -66
            -39 -139 -49 -215 -30 -35 9 -70 24 -77 34 -34 43 27 212 126 351 93 132 346
            357 467 416 112 55 215 67 338 39z m-888 -216 c-260 -319 -332 -576 -204 -731
            61 -74 224 -126 356 -113 128 13 236 70 342 179 46 46 74 67 98 72 19 3 70 22
            115 41 l81 34 51 -48 c58 -54 186 -161 500 -416 580 -473 728 -645 653 -760
            -24 -36 -75 -66 -114 -66 -44 0 -127 74 -436 393 -269 277 -286 290 -350 263
            -61 -25 -83 -95 -48 -151 10 -16 153 -167 317 -334 165 -167 298 -309 296
            -315 -11 -35 -73 -75 -136 -87 -81 -15 -92 -7 -382 287 -149 150 -284 278
            -299 284 -40 15 -92 -4 -116 -44 -24 -39 -24 -73 0 -112 10 -17 128 -142 261
            -278 l242 -246 -27 -36 c-53 -70 -122 -96 -182 -68 -24 11 -142 122 -314 296
            -270 271 -278 278 -317 278 -76 0 -128 -74 -99 -142 8 -18 114 -136 237 -261
            122 -125 220 -230 216 -234 -9 -10 -125 13 -190 37 -76 29 -180 100 -466 320
            -437 336 -626 497 -812 691 -95 99 -174 184 -176 189 -2 5 27 113 63 241 73
            254 160 593 196 762 l22 107 324 0 324 0 -26 -32z"/>
        </g>
    </svg>
</template>
