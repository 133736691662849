import './bootstrap';
import '../css/app.css';
import * as Sentry from "@sentry/vue";

import AOS from 'aos'
import 'aos/dist/aos.css'

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import base from './base';
import { i18nPlugin } from './i18nPlugin';

createInertiaApp({
    title: (title) => title,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const appVue = createApp({ render: () => h(App, props) });

        if (import.meta.env.VITE_SENTRY_DSN_PUBLIC) {
            Sentry.init({
                app: appVue,
                dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
                environment: import.meta.env.APP_ENV,
                trackComponents: true,
                logErrors: true,
                integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
            });
        }

        appVue.use(plugin)
            .mixin(base)
            .use(i18nPlugin, {
                translations: props.initialPage.props.language,
            });

        appVue.AOS = AOS;
        appVue.use(AOS.init({
            offset: -200,
            delay: 100,
            duration: 600,
            easing: 'ease',
        }));

        const components = import.meta.glob([
            './Svgs/*.vue',
        ], { eager: true });
        Object.entries(components).forEach(([path, definition]) => {
            const componentName = path.split('/').pop().replace(/\.\w+$/, '');
            appVue.component(componentName, definition.default);
        });

        appVue.mount(el);

        delete el.dataset.page;

        return appVue;
    },
    progress: {
        color: '#AC182D',
        showSpinner: true,
    },
});
